import { useRequestEvent } from "#imports";
export function useNitroOrigin(e) {
  if (process.server) {
    e = e || useRequestEvent();
    return e.context.siteConfigNitroOrigin;
  }
  if (process.client)
    return window.location.origin;
  return "";
}
